export default {
    name: 'LandingPage',
    created() {
        this.$nextTick(function () {
            // this.$router.go(0)
            // this.hangNav()
            this.getBannerDate()
            this.getShoutQutDate()
            this.getRiclist()
            this.getDayDate()
            this.getFocusDate()
            this.InputIncident()
            this.enterSeekAssignment()
            this.tooltipShowHide()
            $('.banner-text-n').click(function (e) {
                e = e || window.event
                if (e.stopPropagation) {
                    //W3C阻止冒泡方法
                    e.stopPropagation()
                } else {
                    e.cancelBubble = true //IE阻止冒泡方法
                }
            })
            //改变meta的keywords
            $('meta[name="keywords"]').attr(
                'content',
                'Malaysia warrants, structured warrants, RHB, Structured warrants, Warrants, Warrant, Hong Kong warrants, Search Warrant, Search Underlying, Daily Warrant'
            )
        })
    },
    mounted() {
        this.$nextTick(function () {
            this.instagramIFH()
            // this.alterIframe()
            // https://xn--r1a.website/s/rhbwarrantmalaysia"
            // this.$axios.get("api/s/rhbwarrantmalaysia").then(response => {
            //   console.log(response.data);
            // });
        })
    },
    data() {
        return {
            shoutQutLiOne: [],
            judgeLi: null,
            valueLi: null,
            dsplay_nmll: null,
            promote_img: null,
            promote_http: null,
            banner_if: false,
            dayDataArr: [],
            tooltipArr: [],
            ricM: [],
            JoinRIC: null,
            ifClick: false,
            telegram: null,
            bammerImg: []
        }
    },
    methods: {
        getBannerDate: function () {
            let then = this
            $.ajax({
                type: 'post',
                url: this.$js.webservice + 'GetDeployedLandingBanner',
                data: {
                    token: 'webkey',
                    lang: window.sessionStorage.getItem('lang'),
                },
                dataType: 'json',
                success: function (data) {
                    if (data.status === 'SUCCESS') {
                        console.log(data)
                        then.bammerImg = data.text
                        if (data.promote.length === 1) {
                            then.getPromote(data.promote)
                        }
                        // then.DeployedLandingBanner = data.text[0];
                        then.assignmentBanner(data.text[0]);
                        then.telegram = data.telegram
                    }
                },
                error: function (error) {
                    console.log(error)
                },
            })
        },
        assignmentBanner: function (data) {
            var bannerDesktopImg,
                bannerMobileImg,
                barrerDesktopWebp,
                barrerMobileWebp
            if (data.desktop_image_path || data.mobile_image_path) {
                this.banner_if = true
            } else {
                this.banner_if = false
            }
            if (
                data.desktop_image_path !== '' &&
                data.desktop_image_path !== undefined
            ) {
                bannerDesktopImg = data.desktop_image_path
            } else {
                bannerDesktopImg = data.mobile_image_path
            }
            if (
                data.mobile_image_path !== '' &&
                data.mobile_image_path !== undefined
            ) {
                bannerMobileImg = data.mobile_image_path
            } else {
                bannerMobileImg = data.desktop_image_path
            }
            if (
                data.desktop_image_path !== '' &&
                data.desktop_image_path !== undefined
            ) {
                barrerDesktopWebp = data.desktop_image_path
            } else {
                barrerDesktopWebp = data.mobile_image_path
            }
            if (
                data.mobile_image_path !== '' &&
                data.mobile_image_path !== undefined
            ) {
                barrerMobileWebp = data.mobile_image_path
            } else {
                barrerMobileWebp = data.desktop_image_path
            }
            var windowWidth = $(window).width()
            if (windowWidth < 768 || windowWidth === 768) {
                $('.banner-img').attr('src', bannerMobileImg)
                $('.banner-source').attr('src', barrerMobileWebp)
            } else {
                $('.banner-img').attr('src', bannerDesktopImg)
                $('.banner-source').attr('src', barrerDesktopWebp)
            }
            $(window).resize(function () {
                windowWidth = $(window).width()
                if (windowWidth < 768 || windowWidth === 768) {
                    $('.banner-img').attr('src', bannerMobileImg)
                    $('.banner-source').attr('src', barrerMobileWebp)
                } else {
                    $('.banner-img').attr('src', bannerDesktopImg)
                    $('.banner-source').attr('src', barrerDesktopWebp)
                }
            })
            if (data.title && data.content_color) {
                $('.banner-text-h')
                    .css({
                        color: data.title_color,
                    })
                    .find('.normal_title-n')
                    .html(data.title)
                $('.banner-text-c')
                    .html(data.content)
                    .css({ color: data.content_color })
            } else {
                $('.banner-text-h')
                    .find('.normal_title-n')
                    .html(this.$t('m.bannert1'))
                    .css({
                        visibility: 'hidden',
                    })
                $('.banner-text-c')
                    .html(this.$t('m.bannert2'))
                    .css({
                        visibility: 'hidden',
                    })
            }
            if (data.url) {
                $('.carousel-caption_box').click(function () {
                    window.open(data.url)
                })
                $('.carousel-caption_box').css({
                    cursor: 'pointer',
                })
            }
        },
        getShoutQutDate: function () {
            let then = this
            $.ajax({
                type: 'post',
                url: this.$js.webservice + 'GetDeployedAnnouncement',
                data: {
                    token: 'webkey',
                    limit: '5',
                    lang: window.sessionStorage.getItem('lang'),
                },
                dataType: 'json',
                success: function (data) {
                    if (data.status === 'SUCCESS') {
                        then.assignmentShoutQut(data.text)
                    }
                },
                error: function (error) {
                    console.log(error)
                },
            })
        },
        assignmentShoutQut: function (data) {
            for (var s = 0, max = data.length; s < max; s++) {
                if (s < 3) {
                    this.shoutQutLiOne.push(data)
                }
            }
        },
        getLiToShoutoutwarrants: function (v) {
            localStorage.setItem('ShoutoutwarrantsLi', v)
            let routes = this.$router.resolve({
                path: '/id/WarrantShout-Out',
            })
            location.href = routes.href
        },
        gTiem: function (ds) {
            //TODO: remove this
            var nds = []
            var dds = ds.split('-')
            var MMM = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ]
            if (dds.length === 3) {
                var dd = Number(dds[2])
                var mm = MMM[Number(dds[1]) - 1]
                var yyyy = dds[0].substring(4 - 2, 4)
                nds[0] = dd
                nds[1] = mm
                nds[2] = yyyy
            }
            return nds
        },
        getRiclist: function () {
            let then = this
            /*获取ric list*/
            $.ajax({
                type: 'GET',
                url: this.$js.webservice + 'GetRICList',
                data: { token: 'webkey' },
                dataType: 'json',
                success: function (data) {
                    if (data.status === 'SUCCESS') {
                        then.matrixCombobox(data.ric)
                        then.getRic(data.ric)
                    }
                },
                error: function (error) {
                    console.log(error)
                },
            })
        },
        getRic: function (ric) {
            this.JoinRIC = ric
            let then = this
            /*获取ric list*/
            $.ajax({
                type: 'GET',
                url: this.$js.webservice + 'GetScreenerData',
                data: { token: 'webkey', init: '1' },
                dataType: 'json',
                success: function (data) {
                    if (data.status === 'SUCCESS') {
                        $.each(data.underlyings, function (index, value) {
                            then.JoinRIC.unshift({
                                ticker: value.underlying_code,
                                underlying_name: value.underlying_name,
                                dsply_nmll: value.underlying_ticker,
                                ric: value.underlying_ric,
                                judge: 'true',
                            })
                        })
                        then.matrixInput(then.JoinRIC)
                    }
                },
                error: function (error) {
                    console.log(error)
                },
            })
        },
        tooltipRic: function (data) {
            let liRic
            let _this = this

            $.each(_this.ricM, function (index, value) {
                if (value) {
                    liRic =
                        data[value].dsply_nmll +
                        ' (' +
                        data[value].ticker +
                        '): ' +
                        data[value].issuer_name.replace('MACQ', 'Macquarie')
                    _this.tooltipArr.push({
                        name: liRic,
                        ric: value,
                        dsply_nmll: data[value].dsply_nmll,
                    })
                }
            })
        },
        tooltipShowHide() {
            $('.banner-text-n')
                .mouseover(function () {
                    if (!$('#matrix_input_0').val()) {
                        $('.tooltipUl').show()
                    }
                })
                .mouseout(function () {
                    $('.tooltipUl').hide()
                })
        },
        matrixAssignment: function (date, v, liDox, inputDox) {
            let then = this
            $('.' + liDox).empty()
            var matrixLi = ''
            $.each(date, function (index, value) {
                matrixLi =
                    '<li>' +
                    '<span>' +
                    value.liText +
                    '</span>' +
                    '<i class="i_one">' +
                    value.ric +
                    '</i>' +
                    '<i class="i_two">' +
                    value.judge +
                    '</i>' +
                    '<i class="i_three">' +
                    value.name +
                    '</i>' +
                    '</li>'
                $('.' + liDox).append(matrixLi)
            })
            if (date.length == 0) {
                var nomatxiLi = '<li>' + this.$t('m.resultskeyword') + '</li>'
                $('.' + liDox).append(nomatxiLi)
            }
            $('.' + liDox)
                .find('li')
                .click(function () {
                    then.dsplay_nmll = $(this)
                        .find('.i_three')
                        .text()
                    then.judgeLi = $(this)
                        .find('.i_two')
                        .text()
                    then.valueLi = $(this)
                        .find('.i_one')
                        .text()
                    $('#' + inputDox).val(
                        $(this)
                            .find('span')
                            .text()
                    )
                    $('.' + liDox).empty()
                    // $("." + liDox).css({ height: "0" });
                    // $("." + liDox).hide();
                    then.ifClick = true
                })
        },
        enterSeekAssignment: function () {
            let then = this
            $(document).keyup(function (event) {
                if (event.keyCode == 13) {
                    then.seekAssignment()
                }
            })
        },
        toTooltipArr: function (date) {
            localStorage.setItem('detailsRic', date.ric)
            let routes = this.$router.resolve({
                path: '/id/WarrantDetails',
                query: { dsplay_nmll: date.dsply_nmll },
            })
            location.href = routes.href
        },
        seekAssignment: function () {
            if (!this.ifClick) {
                const Val = $('#matrix_input_0').val()
                const NewJoinRIC = this.JoinRIC.filter((item) => {
                    return (
                        Val.toLowerCase() === item.dsply_nmll.toLowerCase() ||
                        Val.toLowerCase() === item.ticker.toLowerCase() ||
                        Val.toLowerCase() === item.underlying_name.toLowerCase()
                    )
                })
                if (NewJoinRIC.length !== 0) {
                    this.valueLi = NewJoinRIC[0].ric
                    this.judgeLi = NewJoinRIC[0].judge
                    this.dsplay_nmll = NewJoinRIC[0].dsply_nmll
                }
            }
            if (this.valueLi) {
                $('.index-matrix-ric0').hide()
                if (this.judgeLi == 'true') {
                    localStorage.setItem('underlyingRic', this.valueLi)
                    let routes = this.$router.resolve({
                        path: '/id/UnderlyingChart',
                        query: { dsplay_nmll: this.dsplay_nmll },
                    })
                    location.href = routes.href
                    this.ifClick = false
                } else {
                    localStorage.setItem('detailsRic', this.valueLi)
                    let routes = this.$router.resolve({
                        path: '/id/WarrantDetails',
                        query: { dsplay_nmll: this.dsplay_nmll },
                    })
                    location.href = routes.href
                    this.ifClick = false
                }
            } else {
                $('#bannerModal').modal('show')
            }
        },
        matrixInput: function (lidate) {
            let then = this
            let OibV
            $('#matrix_input_0').on('input', function () {
                //如果输入空格自动删除
                var inputVal = $(this).val()

                inputVal = $(this)
                    .val()
                    .replace(' ', '')
                if (inputVal) {
                    $('.tooltipUl').hide()
                    $('.index-matrix-ric0').show()
                    then.matrixAssignment(
                        then.$js.filtration(lidate, inputVal),
                        inputVal,
                        'index-matrix-ric0',
                        'matrix_input_0',
                        0
                    )
                    OibV = inputVal
                } else if (inputVal.length === 0) {
                    $('.index-matrix-ric0').hide()
                    $('.index-matrix-ric0').empty()
                    $('.tooltipUl').show()
                }
            })
            $('#matrix_input_0')
                .on('focus', function () {
                    OibV = $(this).val()
                    $(this).val('')
                    $('.tooltipUl').show()
                })
                .on('blur', function () {
                    if (OibV.length !== 0) {
                        $('.banner-name').hide()
                        $('.banner-index').show()
                        $('.tooltipUl').hide()
                    }
                    $(this).val(OibV)
                })
        },
        matrixCombobox: function (data) {
            var arrS = [
                this.$t('m.selectOne'),
                this.$t('m.selectTwo'),
                this.$t('m.selectThree'),
                this.$t('m.selectFour'),
            ]
            var arrRic = []
            let then = this
            $.each($('.index_input_box'), function (i, v) {
                then.$js.jqcombobox('#matrix_input_' + (i + 1))
                $('#matrix_input_' + (i + 1)).combobox({
                    select: function (event, ui) {
                        arrRic[i] = this.value
                        $('.ui-widget_' + (i + 1))
                            .find('input')
                            .blur()
                    },
                })
                then.$js.clickEliminate('.ui-widget_' + (i + 1))
                $('.ui-widget_' + (i + 1))
                    .find('input')
                    .attr('placeholder', arrS[i])
                var ricTxt = ''
                ricTxt += '<option value=" ">' + arrS[i] + '</option>'
                for (var r = 0, max = data.length; r < max; r++) {
                    if (data[r].issuer_name === 'RHB') {
                        ricTxt +=
                            '<option value="' +
                            data[r].ric +
                            '">' +
                            data[r].dsply_nmll +
                            ' (' +
                            data[r].ticker +
                            ')' +
                            '</option>'
                    }
                }
                $('#matrix_input_' + (i + 1)).append(ricTxt)
            })
            this.matrixShow(arrRic)
        },
        matrixShow: function (arr) {
            $('.index-content-button').click(function () {
                localStorage.setItem('priceMatrix', arr)
                location.href = 'warrantmatrix'
            })
        },
        // 获取 Promote 数据
        getPromote: function (data) {
            this.promote_img = data[0].image
            this.promote_http = data[0].hyperlink
            $('#HomePage').modal('show')
        },
        // 获取 Warrants of the day 数据
        getDayDate: function () {
            let then = this
            $.ajax({
                type: 'post',
                url: this.$js.webservice + 'GetDeployedWarrantOfTheDay',
                data: { token: 'webkey' },
                dataType: 'json',
                success: function (data) {
                    if (data.status === 'SUCCESS') {
                        $.each(data.text[0], function (index, value) {
                            if (index.indexOf(index.replace(/ric/gi, '')) > 0) {
                                then.ricM[
                                    Number(index.replace(/[^0-9]/gi, '')) - 1
                                ] = value
                            }
                        })
                        then.getDayDetailData(then.ricM.toString(), then.ricM)
                    }
                },
                error: function (error) {
                    console.log(error)
                },
            })
        },
        // 获取 Warrants of the day 详细数据
        getDayDetailData: function (ric, arr) {
            var $dayData = $('.index-content-centre-data-box')
            let then = this
            if (ric) {
                $.ajax({
                    type: 'GET',
                    url: this.$js.webservice + 'GetMarketData',
                    data: { token: 'webkey', ric: ric, type: 'warrantdata' },
                    dataType: 'json',
                    success: function (data) {
                        then.tooltipRic(data)
                        if (data.status === 'SUCCESS') {
                            $.each(arr, function (index, value) {
                                if (value) {
                                    var BID_PCTCHNG = data[value].ACVOL_1 == 1 ? data[value].BID_PCTCHNG + '' : data[value].PCTCHNG + ''
                                    var underlying_pctchng =
                                        data[value].underlying_pctchng
                                    if (BID_PCTCHNG.indexOf('+') > -1) {
                                        BID_PCTCHNG = then.$js.formatNumber(
                                            BID_PCTCHNG,
                                            1,
                                            0
                                        )
                                    } else if (BID_PCTCHNG.indexOf('-') > -1) {
                                        BID_PCTCHNG = Math.abs(
                                            then.$js.formatNumber(
                                                BID_PCTCHNG,
                                                1,
                                                0
                                            )
                                        )
                                    } else if (
                                        BID_PCTCHNG.indexOf('-') < 0 ||
                                        BID_PCTCHNG.indexOf('+') < 0
                                    ) {
                                        BID_PCTCHNG = then.$js.formatNumber(
                                            BID_PCTCHNG,
                                            1,
                                            0
                                        )
                                    }
                                    if (
                                        underlying_pctchng.indexOf('+') > -1
                                    ) {
                                        underlying_pctchng = then.$js.formatNumber(
                                            underlying_pctchng,
                                            1,
                                            0
                                        )
                                    } else if (
                                        underlying_pctchng.indexOf('-') > -1
                                    ) {
                                        underlying_pctchng = Math.abs(
                                            then.$js.formatNumber(
                                                underlying_pctchng,
                                                1,
                                                0
                                            )
                                        )
                                    } else if (
                                        underlying_pctchng.indexOf('-') <
                                        0 ||
                                        underlying_pctchng.indexOf('+') < 0
                                    ) {
                                        underlying_pctchng = then.$js.formatNumber(
                                            underlying_pctchng,
                                            1,
                                            0
                                        )
                                    }
                                    var left_num
                                    var num = data[value].ACVOL_1 == 1 ? data[value].BID_PCTCHNG : data[value].NET_CHNG_1
                                    if (Number(num) < 0) {
                                        left_num = false
                                    } else {
                                        left_num = true

                                    }
                                    var judgeUp
                                    var right_num
                                    if (
                                        data[
                                            value
                                        ].underlying_pctchng.indexOf(',') > 0
                                    ) {
                                        judgeUp = parseInt(
                                            data[value].underlying_pctchng
                                        )
                                    } else {
                                        judgeUp = Number(
                                            data[value].underlying_pctchng
                                        )
                                    }
                                    if (
                                        data[value].underlying_pctchng &&
                                        judgeUp >= 0
                                    ) {
                                        right_num = true
                                    } else if (
                                        data[value].underlying_pctchng &&
                                        judgeUp < 0
                                    ) {
                                        right_num = false
                                    }

                                    then.dayDataArr[index] = {
                                        dsply_nmll: data[value].dsply_nmll,
                                        BID_PCTCHNG: BID_PCTCHNG + '%',
                                        BID_NETCHNG:
                                            underlying_pctchng + '%',
                                        left_num: left_num,
                                        right_num: right_num,
                                        underlying_ticker:
                                            data[value].underlying_ticker,
                                    }
                                }
                            })
                            then.$forceUpdate()
                        }
                    },
                    error: function (error) {
                        console.log(error)
                    },
                })
            } else {
                $dayData.eq(Number(index.replace(/[^0-9]/gi, '')) - 1).hide()
            }
        },
        //跳转到WarrantDetails
        toDetails: function (ric, judge, index) {
            if (judge) {
                localStorage.setItem('detailsRic', this.ricM[index])
                let routes = this.$router.resolve({
                    path: '/id/WarrantDetails',
                    query: { dsplay_nmll: ric },
                })
                location.href = routes.href
            } else {
                localStorage.setItem('underlyingRic', ric)
                let routes = this.$router.resolve({
                    path: '/id/UnderlyingChart',
                    query: { dsplay_nmll: ric },
                })
                location.href = routes.href
            }
        },
        // 改 iframe的跳转地址
        alterIframe: function () {
            $('#ifr-grid').show()
            // $('.tgme_container_box').css({
            //   'width': ($('#ifr').width() - 19) + 'px'
            // })
        },
        //获取 focus数据
        getFocusDate: function () {
            let then = this
            $.ajax({
                type: 'post',
                url: this.$js.webservice + 'GetDeployedWarrantInFocus',
                data: {
                    token: 'webkey',
                    lang: window.sessionStorage.getItem('lang'),
                },
                dataType: 'json',
                success: function (data) {
                    if (data.status === 'SUCCESS') {
                        then.matrixFocus(data.text)
                    }
                },
                error: function (error) {
                    console.log(error)
                },
            })
        },
        // focus 赋值
        matrixFocus: function (data) {
            var $focus = $('.indx-content-focus')
            $focus.attr(
                'style',
                'background-image:url("' + data[0].image + '")'
            )
            $focus.find('.indx-content-focus-con-box').append(data[0].content)
        },
        // banner
        // input框事件
        InputIncident: function () {
            $('.banner-con').mouseenter(function () {
                $('.banner-name').hide()
                $('.banner-index').show()
                if ($('.index-matrix-ric0').val()) {
                    $('.index-matrix-ric0').show()
                }
            })
            $('.banner-text-n').mouseleave(function () {
                if ($('#matrix_input_0').val().length === 0) {
                    $('.banner-name').show()
                    $('.banner-index').hide()
                } else {
                    $('.banner-name').hide()
                    $('.banner-index').show()
                }
                $('.index-matrix-ric0').hide()
            })
        },
        // instagram
        instagramIFH: function () {
            iFrameResize({ log: true }, '#instagramIF')
        },
        // 悬挂导航
        hangNav() {
            let butUlBoxW = $(window).width()
            let butUlLiW
            if (butUlBoxW > 992) {
                $('.butUlBox').find('.navArrows').hide()
                $('.butUl').find('li').find('.btn').width('auto')
            } else if (butUlBoxW <= 992 && butUlBoxW > 530) {
                $('.butUlBox').find('.navArrows').show()
                butUlLiW = butUlBoxW / 3
                $('.butUl').find('li').find('.btn').width(butUlLiW - 30)
            }
            else if (butUlBoxW <= 530) {
                $('.butUlBox').find('.navArrows').show()
                butUlLiW = butUlBoxW / 2
                $('.butUl').find('li').find('.btn').width(butUlLiW - 30)
            } else {
                $('.butUlBox').find('.navArrows').show()
                butUlLiW = butUlBoxW / 1
                $('.butUl').find('li').find('.btn').width(butUlLiW - 30)
            }
            $(window).resize(() => {
                butUlBoxW = $(window).width()
                if (butUlBoxW > 992) {
                    $('.butUlBox').find('.navArrows').hide()
                    $('.butUl').find('li').find('.btn').width('auto')
                } else if (butUlBoxW <= 992 && butUlBoxW > 530) {
                    $('.butUlBox').find('.navArrows').show()
                    butUlLiW = butUlBoxW / 3
                    $('.butUl').find('li').find('.btn').width(butUlLiW - 30)
                } else if (butUlBoxW <= 530 && butUlBoxW > 330) {
                    $('.butUlBox').find('.navArrows').show()
                    butUlLiW = butUlBoxW / 2
                    $('.butUl').find('li').find('.btn').width(butUlLiW - 30)
                } else {
                    $('.butUlBox').find('.navArrows').show()
                    butUlLiW = butUlBoxW / 1
                    $('.butUl').find('li').find('.btn').width(butUlLiW - 30)
                }
            })
        },
        arrowsLeft() {
            let num = $('.butUlBox').find('.butUl').find('li').outerWidth() + 20
            let scrollLeft = $('.butUlBox').find('.butUl').scrollLeft()
            $('.butUlBox').find('.butUl').scrollLeft(-num + scrollLeft)
        },
        arrowsRight() {
            let num = $('.butUlBox').find('.butUl').find('li').outerWidth() + 20
            let scrollLeft = $('.butUlBox').find('.butUl').scrollLeft()
            $('.butUlBox').find('.butUl').scrollLeft(num + scrollLeft)
        }
    },
}
